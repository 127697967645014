$sm: 640px;
$md: 720px;
$lg: 960px;
$xl: 1080px;

$focus-shadow: 0 0 0 0.2rem #a6d5fa;

$gold: linear-gradient(
    45deg,
    hsl(48, 91%, 41%) 0%,
    hsl(48, 91%, 50%) 50%,
    hsl(48, 91%, 50%) 51%,
    hsl(48, 85%, 62%) 100%
);
$silver: linear-gradient(
    45deg,
    hsl(202, 5%, 68%) 0%,
    hsl(202, 7%, 78%) 50%,
    hsl(202, 7%, 78%) 51%,
    hsl(214, 19%, 93%) 100%
);
$bronze: linear-gradient(
    45deg,
    hsl(24, 35%, 32%) 0%,
    hsl(24, 22%, 52%) 50%,
    hsl(24, 22%, 52%) 51%,
    hsl(33, 100%, 83%) 100%
);
$blue: linear-gradient(
    45deg,
    hsl(209, 100%, 40%) 0%,
    hsl(209, 79%, 65%) 51%,
    hsl(209, 79%, 65%) 51%,
    hsl(209, 84%, 65%) 100%
);
$light-blue: linear-gradient(
    45deg,
    hsl(209, 100%, 60%) 0%,
    hsl(209, 79%, 75%) 51%,
    hsl(209, 79%, 75%) 51%,
    hsl(209, 84%, 80%) 100%
);
$dark-blue: linear-gradient(
    45deg,
    hsl(209, 100%, 30%) 0%,
    hsl(209, 79%, 50%) 51%,
    hsl(209, 79%, 50%) 51%,
    hsl(209, 84%, 60%) 100%
);

.rating-card {
    align-items: center;
    appearance: none;
    border-radius: 4px;
    border-width: 0;
    box-sizing: border-box;
    color: black;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.63);
    cursor: pointer;
    display: inline-flex;
    width: 2.7rem;
    height: 2.7rem;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;

    @media only screen and (max-width: $xl) {
        width: 3rem;
        height: 3rem;
    }

    &.rating-card-golden {
        background: $gold;
        border: 1px solid #9b7d04;
        box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #9b7d04 0 -3px 0 inset;
    }

    &.rating-card-silver {
        background: $silver;
        border: 1px solid #7f8385;
        box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #7f8385 0 -3px 0 inset;
    }

    &.rating-card-bronze {
        background: $bronze;
        color: white;
        border: 1px solid #6e4c35;
        box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #6e4c35 0 -3px 0 inset;
    }

    &.rating-card-dimmed {
        background: $light-blue;
        font-weight: 600;
        border: 1px solid #04339b;
        box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #04339b 0 -3px 0 inset;
    }

    &.rating-card-selected {
        background: $blue;
        font-weight: 600;
        border: 2px solid #04339b;
        transform: scale(1.1);
        box-shadow: $focus-shadow;
        color: white;
    }

    &.rating-card-dimmed.rating-card-selected {
        background: $dark-blue;
        transform: scale(1);
    }

    &:hover {
        filter: brightness(1.1);
        transform: translateY(-1px);
    }
}
